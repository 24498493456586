<template>
  <section id="no-members-warning" class="tlw-h-full tlw-w-full tlw-flex tlw-flex-col tlw-justify-center">
    <b-img-lazy class="no-members-warning__image tlw-mb-5" :src="noDataImg"></b-img-lazy>
    <h3 id="title" class="tlw-text-center tlw-my-4 tlw-font-bold tlw-text-xl">
      {{ $t('sparkmembers.members-list.no-members-warning.title') }}
    </h3>
    <div>
      <p class="text-center">{{ $t('sparkmembers.members-list.no-members-warning.p1') }}</p>
      <p class="text-center">{{ $t('sparkmembers.members-list.no-members-warning.p2') }}</p>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      noDataImg: require('@/assets/images/commons/no_data.svg'),
    };
  },
};
</script>

<style lang="scss">
.no-members-warning__image {
  height: 120px;
}
</style>
